@import '../../utilities/colors';

#shelf-setup-form {
  width: auto;
  display: flex;
  flex-direction: column;

  .sort-by {
    max-width: 100%;
    label {
      flex-wrap: wrap;
      justify-content: flex-start;

      span {
        margin-right: 1rem;
      }

      & > * {
        margin-bottom: 0.7rem;
        margin-right: 0.2rem;
      }

      .dimensions-control {
        margin-right: 0;
        margin-left: 1rem;
        white-space: nowrap;
      }
    }
  }
}

fieldset {
  margin-bottom: 30px;
}

label {
  color: $light-gray;
  font-weight: 400;
  height: auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

input,
select {
  width: 80px;
  background: $rifle-green;
  border: 1px solid $light-gray;
  padding: 5px;
  border-radius: 5px;
  color: $light-gray;

  &::placeholder {
    color: $morning-blue;
  }
}

.shelf-size-inputs {
  display: flex;
  align-items: center;
  span {
    margin: 0 10px;
  }
}
