@import '../../utilities/colors';
@import '../../utilities/mixins';

.release-card {
  background: $light-gray;
  color: $rifle-green;
  transition: all 0.5s ease-in-out;
  padding: 1.5rem;
  border-radius: 5px;
}

.release-card-controls {
  display: flex;
  justify-content: space-between;
  margin-bottom: 1rem;
}

.release-info {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: space-between;
}

.release-cover {
  display: flex;
  align-items: center;
  height: 150px;
  img {
    width: auto;
    height: 100%;
    margin: 0 auto;
    @include cover-box-shadow;
  }
}

.hidden {
  display: none;
}
