#dashboard-container {
  position: relative;
  width: 100%;
  display: flex;
  align-items: stretch;
  justify-content: space-between;
}

#sidebar {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-self: flex-start;
  padding-top: 3.5rem;
  width: 25%;

  &.form-is-visible {
    align-self: flex-end;
  }
}

#output {
  flex-grow: 1;
  display: inline-block;
  margin-left: 1rem;
}
