@import './colors';

@mixin box-shadow {
  box-shadow: 0 5px 1rem rgba($black-olive, 0.3), 0 2px 0.2rem rgba($black-olive, 0.5);
}

@mixin cover-box-shadow {
  box-shadow: 0 5px 1rem rgba($rifle-green, 0.3), 0 2px 0.2rem rgba($rifle-green, 0.5);
}

// margins and paddings

$max: 10;
$offset: 1;
$unit: 'rem'; // Feel free to change the unit.

@mixin margin-and-padding($className, $styleName) {
  $i: 0;
  @while $i <= $max {
    #{$className + $i} {
      #{$styleName}: #{$i + $unit};
    }
    $i: $i + $offset;
  }
}
