@import '../../../utilities/colors';

.dimensions-control {
  .icon-btn {
    border: 1px solid $light-gray;

    &:hover {
      border: none;
    }

    &:first-child {
      margin-right: 0.5rem;
    }

    svg {
      height: 20px;
      width: 20px;
    }
  }
}
