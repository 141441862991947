@import '../../utilities/colors';

.shelving-unit {
  background-color: rgba(0, 0, 0, 0);
  border: 20px solid $light-gray;
  width: auto;
  margin: 3.5rem auto 0;
  white-space: nowrap;

  td {
    width: 200px;
    height: 200px;
    padding: 0.25em;
    border: 5px solid $light-gray;
    display: inline-flex;
    align-items: stretch;
    justify-content: space-between;
  }

  // change the following, add an id to the last not empty shelf in react instead
  .last-populated-shelf {
    justify-content: flex-start;

    .release {
      flex-grow: initial;
    }
  }
}
