.profile {
  p {
    margin-bottom: 0.5rem;
    text-align: center;
  }

  .profile-sync {
    text-align: center;
    margin-top: 2rem;

    .btn {
      margin-bottom: 0.5rem;
    }
  }
}
