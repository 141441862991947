@import url('https://fonts.googleapis.com/css2?family=Noto+Sans:ital,wght@0,400;0,700;1,400;1,700&display=swap');
@import './utilities/colors';
@import './utilities/mixins';

body {
  background-color: $rifle-green;
  color: $light-gray;
  min-height: 100vh;

  * {
    font-family: 'Noto Sans', sans-serif;
  }
}

strong {
  color: inherit;
}

.App {
  padding: 3.5rem 1.5rem;
  min-height: 100vh;
  display: flex;
  align-items: stretch;
}

#root {
  @include margin-and-padding('.pl-', 'padding-left');
  @include margin-and-padding('.pr-', 'padding-right');
  @include margin-and-padding('.pt-', 'padding-top');
  @include margin-and-padding('.pb-', 'padding-bottom');
  @include margin-and-padding('.ml-', 'margin-left');
  @include margin-and-padding('.mr-', 'margin-right');
  @include margin-and-padding('.mt-', 'margin-top');
  @include margin-and-padding('.mb-', 'margin-bottom');

  p.is-error {
    color: $light-pink;
    font-size: 0.8em;
    text-align: left;
    line-height: 1em;
    margin-top: 0;
    margin-bottom: 1rem;
  }
}

.m0auto {
  margin: 0 auto;
}

.fz-2 {
  font-size: 2rem;
}

section {
  width: 100%;
}

#phone-mask {
  text-align: center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 1100;
  background: $rifle-green;
  display: none;
  align-items: center;
  justify-content: center;
  padding: 1.5rem;
}

@media (max-width: 961px) {
  #phone-mask {
    display: flex;
  }
}

// _______________________ Sidebar

h2 {
  margin-bottom: 1rem;
}

.spins {
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

a {
  color: $light-gray;
  transition: color ease-in-out 100ms;

  &:hover {
    color: $platinum;
  }

  &.is-blue {
    color: $lila;

    &:hover {
      color: scale-color($lila, $lightness: -15%, $saturation: 30%);
    }
  }
}

.btn {
  border: none;
  background-color: $light-gray;
  color: $black-olive;
  padding: 0.5rem 1.5rem;
  border-radius: 5px;
  transition: all 100ms ease-in-out;

  &:hover {
    cursor: pointer;
    @include box-shadow;
  }

  &.icon-btn {
    background-color: initial;
    height: 32px;
    width: 32px;
    margin-top: 0;
    color: $light-gray;
    border-radius: 50%;
    padding: 0;
    display: inline-flex;
    align-items: center;
    justify-content: center;

    svg {
      width: 32px;
      height: 32px;
    }

    &:hover {
      color: $platinum;
      background: rgba($light-gray, 0.3);
      box-shadow: none;
    }

    &:focus {
      outline: none;
    }

    &.is-large {
      height: 50px;
      width: 50px;

      svg {
        height: 50px;
        width: 50px;
      }
    }

    &.is-dark {
      color: $black-olive;

      &:hover {
        background: rgba($black-olive, 0.1);
      }
    }
  }
}

// Search Bar

.searchbar {
  width: 100%;
  margin: 0 auto;
  display: block;
  font-size: 1rem;
  padding: 0.5rem 1rem;
  margin-bottom: 0.5rem;
}

.search-wrapper {
  position: relative;
}

.search-results {
  list-style-type: none;
  background-color: $platinum;
  position: absolute;
  border-radius: 5px;
  width: 100%;
  top: 2.5rem;
  z-index: 1000;
  max-height: 50vh;
  overflow-y: scroll;

  li {
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: $light-gray;
    padding: 0.5rem 1rem;
    color: $rifle-green;

    &:not(:last-of-type) {
      border-bottom: 1px solid $light-gray;
    }

    &:hover {
      background: $light-gray;
      color: $black-olive;
      cursor: pointer;
    }
  }
}

.search-result-cover {
  width: 50px;
}

// _____________________ Output

.release {
  display: inline-block;
  flex-grow: 1;
  background-color: $morning-blue;

  &:hover {
    background-color: $platinum;
    cursor: pointer;
  }
}

.highlighted {
  background-color: $rifle-green;
}

#beta-user-form {
  width: 500px;
  margin: 0 auto;
  padding-top: 10rem;
  display: flex;
  flex-direction: column;
  text-align: center;
  align-items: center;

  input {
    width: 200px;
  }

  * {
    margin-bottom: 1rem;
  }
}
