@import '../../utilities/colors';

.loader-wrapper {
  color: $light-gray;
  text-align: center;
  margin: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  min-height: 100vh;
  background-color: $rifle-green;
  z-index: 100;

  h2 {
    color: inherit;
    font-weight: 400;
    font-size: 1.5rem;
    max-width: 80%;
  }
  .loader {
    width: 3rem;
    height: 3rem;
  }
}
