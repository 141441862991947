@import "../../utilities/colors";

.tooltip-container {
  position: relative;
  color: $light-gray;
}

.tooltip-box {
  position: absolute;
  background: rgba($platinum, 0.6);
  color: $black-olive;
  padding: 0.7rem 1rem;
  border-radius: 5px;
  left: calc(100% + 10px);
  top: 50%;
  transform: translateY(-50%);
  display: none;
  line-height: 1.1em;
  width: 250px;
  font-size: 1rem;

  &.visible {
    display: block;
  }
}

.tooltip-arrow {
  position: absolute;
  top: 50%;
  left: -10px;
  border-width: 5px;
  border-style: solid;
  border-color: transparent rgba($platinum, 0.6) transparent transparent;
  transform: translateY(-50%);
}

#shelf-setup-form {
  .tooltip-container {
    position: absolute;
    left: 26%;
  }
}
