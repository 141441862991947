.question {
  h2 {
    font-weight: 800;
    font-size: 1.5rem;
    margin-bottom: 0.5rem;
    line-height: 1.1em;
  }
}

.faq {
  ul {
    padding-left: 1.5rem;
  }
  li {
    list-style-type: disc;
  }
}
