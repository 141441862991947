.login-form {
  width: 15rem;
  margin: 20vh auto;

  * {
    width: 100%;
  }

  label {
    font-size: 1rem;
    line-height: 1em;
    margin-bottom: 0.5rem;
  }
  input {
    margin-bottom: 1rem;
  }

  button {
    margin-top: 1rem;
  }

  p {
    margin-top: 1rem;
    text-align: center;
  }
}
