@import "../../utilities/colors";

.main-nav {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 3.5rem;
  padding: 1rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  z-index: 1000;

  ul {
    display: flex;
    align-items: center;
    li {
      &:not(:last-of-type) {
        margin-right: 1rem;
      }
    }
  }
}

.brand {
  color: $light-gray;
  font-size: 2rem;
  font-weight: 700;
  line-height: 0.9em;
  z-index: 1000;
  // align-self: flex-start;

  small {
    font-weight: 300;
    font-style: italic;
    text-transform: lowercase;
  }
}
